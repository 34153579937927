
import DatePicker from "@/components/utils/DatePicker.vue";
import ImageSelector from "@/components/utils/PhotoSelect.vue";
import ValidationMixin from "@/mixins/validation";
import { CardTypes, FarmerCard } from "@/store/modules/card/card.types";
import {
  AgroEnterprise,
  EnterpriseTypes,
} from "@/store/modules/enterprise/enterprise.types";
import {
  AddFarmerPayload,
  Farmer,
  FarmerTypes,
} from "@/store/modules/farmers/farmers.types";
import { DefaultState, LocationInfo, Pagination } from "@/types/types";
import { Validator } from "@/types/validator";
import {
  BaseDistrict,
  County,
  District,
  districts,
  getDistrict,
  Parish,
  SubCounty,
  Village,
} from "akwap";
import { Component, Mixins, Ref, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const farmerX = namespace("Farmers");
const enterpriseX = namespace("Enterprise");
const card = namespace("Card");

@Component({
  components: { ImageSelector, DatePicker },
})
export default class AddFarmer extends Mixins(ValidationMixin) {
  @farmerX.State("add")
  public addFarmer!: DefaultState;

  @farmerX.Mutation(FarmerTypes.SET_ADD_FARMER_DIALOG)
  public setAddFarmer!: (addFarmer: boolean) => void;

  @farmerX.Action(FarmerTypes.ADD_FARMER)
  public saveFarmer!: (farmer: AddFarmerPayload) => Promise<void>;

  @enterpriseX.Action(EnterpriseTypes.LOAD_ENTERPRISES)
  public loadEnterprises!: () => Promise<void>;

  @enterpriseX.State("enterprises")
  public enterprises!: AgroEnterprise[];

  @enterpriseX.State("addFarmerRef")
  public addFarmerRef!: number;

  @card.State("cards")
  public cards!: FarmerCard[];

  public ugandaDistricts: BaseDistrict[] = districts;

  public addFarmerStep = 1;

  public isValid = false;
  public userDetailsValid = false;
  public cardsValid = false;
  public locationValid = false;
  public othersValid = false;

  public farmer: Farmer = {
    first_name: "",
    last_name: "",
    license_no: "",
    dob: "",
    phone_no: "",
    nfc_card_no: "",
    enterprise: [],
    card: {
      type: "national-id",
    },
    gender: "M",
    location: {
      district: "",
      county: "",
      sub_county: "",
      parish: "",
      village: "",
    },
  };

  // Images
  public profile = require("@/assets/images/default-image.png");
  public idFront = require("@/assets/images/front.png");
  public idBack = require("@/assets/images/back.png");

  public profileValid = true;
  public idFrontValid = true;
  public idBackValid = true;

  @card.Action(CardTypes.LOAD_CARDS)
  public loadCards!: (pagination: Pagination) => void;

  @Ref("profile") public profileRef!: ImageSelector;
  @Ref("idFront") public idFrontRef!: ImageSelector;
  @Ref("idBack") public idBackRef!: ImageSelector;

  @Ref("form") public formRef!: Validator;
  @Ref("userDetailsForm") public userDetailsFormRef!: Validator;
  @Ref("cardsForm") public cardsFormRef!: Validator;
  @Ref("locationForm") public locationFormRef!: Validator;
  @Ref("otherForm") public otherFormRef!: Validator;

  closeAddFarmerDialog(): void {
    this.setAddFarmer(false);
  }

  async saveFarmerToDb(): Promise<void> {
    await this.otherFormRef.validate();

    if (this.othersValid) {
      const payload: AddFarmerPayload = {
        first_name: this.farmer.first_name,
        last_name: this.farmer.last_name,
        other_names: this.farmer.other_names,
        dob: this.farmer.dob,
        nfc_card_no: this.farmer.nfc_card_no,
        enterprise: this.farmer.enterprise,
        phone_no: "+256" + this.farmer.phone_no,
        photo: this.farmer.photo ?? "",
        card: {
          type: this.farmer.card.type,
          no: this.farmer.card.no ?? "",
          front: this.farmer.card.front ?? "",
          back: this.farmer.card.back ?? "",
        },
        gender: this.farmer.gender,
        in_group: this.farmer.in_group,
        cooperative: this.farmer.cooperative,
        group: this.farmer.group,
        people_in_household: this.farmer.people_in_household,
        land_size: this.farmer.land_size,
        village: this.location.village?.id ?? -1,
        agent: undefined,
      };
      await this.saveFarmer(payload);
    }
  }

  async continueFromProfilePhoto(): Promise<void> {
    this.farmer.photo = await this.profileRef.crop();
    this.profileValid = !!this.farmer.photo;

    if (this.profileValid) {
      this.addFarmerStep = 2;
    }
  }

  async continueFromUserDetails(): Promise<void> {
    await this.userDetailsFormRef.validate();

    if (this.userDetailsValid) {
      this.addFarmerStep = 3;
    }
  }

  async continueFromCards(): Promise<void> {
    await this.cardsFormRef.validate();

    this.farmer.card.back = await this.idBackRef.crop();
    this.farmer.card.front = await this.idFrontRef.crop();

    this.idBackValid = !!this.farmer.card.back;
    this.idFrontValid = !!this.farmer.card.front;

    if (this.cardsValid && this.idBackValid && this.idFrontValid) {
      this.addFarmerStep = 4;
    }
  }

  async continueFromLocation(): Promise<void> {
    await this.locationFormRef.validate();

    if (this.locationValid) {
      this.addFarmerStep = 5;
    }
  }

  // Location Parameters
  private districtInfo?: District;
  // Location
  public location: LocationInfo = {};
  public dealerDistrict?: District;
  public counties: County[] = [];
  public subCounties: SubCounty[] = [];
  public parishes: Parish[] = [];
  public villages: Village[] = [];

  @Watch("location.district")
  onDistrictChanged(value?: BaseDistrict): void {
    this.districtInfo = getDistrict(value?.name ?? "");
    if (this.districtInfo) {
      this.counties = this.districtInfo.counties;
    }
  }

  @Watch("location.county")
  onCountyChanged(value?: County): void {
    const county = this.counties.find((county) => county.id == value?.id);
    if (county) {
      this.subCounties = county.sub_counties;
    }
  }

  @Watch("location.sub_county")
  onSubCountyChanged(value?: SubCounty): void {
    const subCounty = this.subCounties.find(
      (subCounty) => subCounty.id == value?.id
    );
    if (subCounty) {
      this.parishes = subCounty.parishes;
    }
  }

  @Watch("location.parish")
  onParishChanged(value?: Parish): void {
    const parish = this.parishes.find((parish) => parish.id == value?.id);
    if (parish) {
      this.villages = parish.villages;
    }
  }

  mounted(): void {
    if (this.enterprises.length == 0) {
      this.loadEnterprises();
    }
    if (this.cards.length == 0) {
      this.loadCards({ page: 1, limit: 10 });
    }
  }
}
